import moment from "moment-timezone";

const timestampToDate = function (date, tz = process.env.VUE_APP_TZ) {
    return moment.tz(moment.unix(date), tz).format("DD.MM.YYYY");
};

const timestampToDateTime = function (date, tz = process.env.VUE_APP_TZ) {
    return moment.tz(moment.unix(date), tz).format("DD.MM.YYYY HH:mm");
};

export { timestampToDate, timestampToDateTime };
