import { Middlewares } from "@/app/shared/services/app-router/middlewares";

export default [
    {
      path: '/credit-rating',
      name: 'CreditRating',
      component: () => import('./views/pageCreditRating.vue'),
      meta: {
        title: 'Кредитный рейтинг: опрос',
        wfPage: '652921c910bae02d8870dddd'
      }
    }
  ];
