import limit from "./limit";
import { isoToDate, isoToDateTime } from "./isodate";
import { timestampToDate, timestampToDateTime } from "./timestamp";

export default {
    install(Vue) {
        Vue.filter("limit", limit);
        Vue.filter("isoToDate", isoToDate);
        Vue.filter("isoToDateTime", isoToDateTime);
        Vue.filter("timestampToDate", timestampToDate);
        Vue.filter("timestampToDateTime", timestampToDateTime);
    },
};
