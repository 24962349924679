import moment from "moment-timezone";

const isoToDate = function (date, tz = process.env.VUE_APP_TZ) {
    return moment.utc(date).tz(tz).format("DD.MM.YYYY");
};

const isoToDateTime = function (date, tz = process.env.VUE_APP_TZ) {
    return moment.utc(date).tz(tz).format("DD.MM.YYYY HH:mm");
};

export { isoToDate, isoToDateTime };
