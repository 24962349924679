<template>
  <div class="footer">
    <div class="section last">
      <div class="container last w-container">
        <div class="footer-block">
          <a @click="goHome" id="w-node-bd35c41e-61b3-501a-b5b5-76a67d389e27-e94b0a3f" class="navbar-logo-link w-nav-brand"><img src="../../../../../public/images/Logo-Vse.-Zaimi.svg" loading="lazy" alt="" class="navbar-logo"></a>
          <div class="text-classic white mini">© 2024, Всем займы</div>
        </div>
        <div class="grid-colum top">
          <p class="plane-text">
            Общество с ограниченной ответственностью микрокредитная компания «ТРИЛЛИОН ЗАЕМ» (ООО МКК "Триллион Заем")
            зарегистрировано в Государственном реестре микрофинансовых организаций 14 мая 2024 года.<br>
            Номер свидетельства:<br>
            24-030-15-010050 — <a href="https://www.cbr.ru/">cbr.ru</a><br>
            ИНН: 3257084431<br>
            ОГРН: 1223200004026<br>
            Адрес: 241050, Брянская область, г Брянск, ул Ромашина, д. 32, офис 10
            <br><br>
            ООО МКК "Триллион Заем" использует cookie - файлы с данными о прошлых посещениях сайта для персонализации
            сервисов и удобства пользователей.<br>
            Подробнее —
            <a href="#" @click="goToPolicyPersonalData">Политика конфиденциальности</a>.
            <br><br>

            <a href="#" @click="goToExtractFromRegistry">Выписка из государственного реестра микрофинансовых организаций</a>.
            <br><br>
            <a href="https://www.cbr.ru/">Официальный сайт Банка России</a>
            <br><br>
            <a href="https://www.cbr.ru/reception/">Интернет-приемная Банка России</a>
            <br><br>
            Вы можете обратиться с жалобой в <a href="https://finombudsman.ru/">Службу финансового уполномоченного</a>.
            <br><br>
            Контакты Службы обеспечения деятельности финансового уполномоченного: 119017, г. Москва,
            Старомонетный переулок, дом 3. Почтовый адрес: 119017, г. Москва, Старомонетный переулок, дом 3,
            получатель АНО «СОДФУ». Номер телефона:8 (800) 200-00-10 (бесплатный звонок по России).
            Официальный сайт финансового уполномоченного: <a href="https://finombudsman.ru/">www.finombudsman.ru</a>.
            <br><br>
            Оставляя свои контактные данные на сайте, пользователь дает свое согласие на получение информации
            о компании ООО МКК "Триллион Заем" и партнерах компании.
            <br><br>
            Диапазон полной стоимости кредита (ПСК) составляет от 0,000% до 52% годовых.
            Процентная ставка в день от 0% до 0,8%.<br>
            Сумма займа от 5 до 30 тыс.руб. Срок займа от 91 до 365 дня. Без залога и обеспечения.<br>
            Расчет стоимости займа производится:<br>
            Сумма к возврату = Сумма Займа + Сумма Переплаты, где<br>
            Сумма переплаты = Сумма Займа * Период Займа * (Ставка в % / 100)
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Footer',
  methods: {
    goHome() {
      this.$router.push('/');
    },
    goToPolicyPersonalData() {
      this.$router.push('/documents/privacy-policy');
      window.scrollTo(0, 0);
    },
    goToExtractFromRegistry() {
      this.$router.push('/documents/extract-from-registry');
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style>
.footer a {
  color: var(--white-72);
}
</style>
