import { AxiosClient } from '@/app/shared/services';
import { toFormData } from "@/app/shared/services/helpers";

const state = {
  personal_data: {
    profile: {},
    passport: {},
    address: {},
  },
};

const getters = {
  PERSONAL_DATA: (state) => state.personal_data,
};

const mutations = {
  SET_PERSONAL_DATA: (state, payload) => {
    let type = payload.type;
    if (type === 'profile') {
      state.personal_data.profile = payload.data;
    } else if (type === 'passport') {
      state.personal_data.passport = payload.data;
    } else if (type === 'address') {
      state.personal_data.address = payload.data;
    } else if (type === 'all') {
      state.personal_data.passport = payload.data.passport;
      state.personal_data.profile = payload.data.profile;
      state.personal_data.address = payload.data.address;
    }
  },
};

const actions = {
  GET_PERSONAL_DATA: (context, type) => {
    return new Promise((resolve, reject) => {
      let params = {
        "type": type
      };

      AxiosClient.get("/profiles/me", { params: params })
        .then((data) => {

          let data_for_set = {
            type: type,
            data: data.data
          };

          context.commit("SET_PERSONAL_DATA", data_for_set);
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка получения данных: ', error);
          reject();
        });
    });
  },
  CHANGE_PASSPORT_DATA: (context, payload) => {
    return new Promise((resolve, reject) => {
      AxiosClient.post("/profiles/me/passport", payload)
        .then(() => {
          context.dispatch('GET_PERSONAL_DATA', 'passport');
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка получения данных: ', error);
          reject();
        });
    });
  },
  CHANGE_ADDRESS_DATA: (context, payload) => {
    return new Promise((resolve, reject) => {
      AxiosClient.post("/profiles/me/address", payload)
        .then(() => {
          context.dispatch('GET_PERSONAL_DATA', 'address');
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка получения данных: ', error);
          reject();
        });
    });
  },
  CHANGE_PROFILE_DATA: (context, payload) => {
    return new Promise((resolve, reject) => {
      AxiosClient.post("/profiles/me/profile", payload)
        .then(() => {
          context.dispatch('GET_PERSONAL_DATA', 'profile');
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка получения данных: ', error);
          console.error('Тип ошибки: ', error.response.data.exception);

          reject();
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
