import { AxiosClient } from '@/app/shared/services';
import { toFormData } from "@/app/shared/services/helpers";

const state = {
  registration_data: {
    profile: {
      email: '', //+
      phone: '', //+
      name: '', //+
      surname: '', //+
      middle_name: '', //+
      uuid_phone: '', //+
      birth_date: '' //+
    },

    passport: {
      series: '', //+
      number: '', //+
      issued_at: '', //+
      issued_by: '', //+
      division_code: '', //+
      birth_place: '', //+
      gender: false
    },

    address: {
      region: '', //+
      city: '', //+
      street: '', //+
      house: '', //+
      apartment: '' //+
    },

    incomes: {
      sector_id: null,
      primary_income: 0,
      secondary_income: 0
    }
  },
  sectors: null,
};

const getters = {
  REGISTRATION_DATA: (state) => state.registration_data,
  SECTORS: (state) => state.sectors,
};

const mutations = {
  SET_REGISTRATION_DATA: (state, payload) => {
    state.registration_data = payload;
  },
  SET_SECTORS: (state, payload) => {
    state.sectors = payload;
  },
};

const actions = {
  GET_SECTORS_LIST: (context) => {
    return new Promise((resolve, reject) => {
      AxiosClient.get("/sectors/get")
        .then((data) => {
          context.commit("SET_SECTORS", data.data);

          context.state.registration_data.incomes.sector_id = data.data[0].id;
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка доставания данных: ', error);
          reject();
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
