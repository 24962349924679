<template>
  <div>

    <div data-animation="default" class="navbar-component w-nav"
         data-easing2="ease"
         data-easing="ease"
         data-collapse="medium"
         data-w-id="11c4cfc9-926a-e542-1b82-38094d6cd2e0" role="banner"
         data-no-scroll="1" data-duration="250" data-doc-height="1"
         style="z-index: 999;">
      <div class="navbar-container">
        <a @click="goHome" aria-current="page" class="navbar-logo-link w-nav-brand w--current"><img src="../../../../../public/images/Logo.svg" loading="lazy" alt="" class="navbar-logo"></a>
        <div id="w-node-df11f607-f470-9d9d-9899-a77ebcac00cb-4d6cd2e0" class="text-middle-nav">Моментальные займы на карту</div>
        <nav role="navigation" class="navbar-menu w-nav-menu">
          <div v-if="currentRoute === '/main'">
            <a href="#Kak-rabotaet" class="navbar-link w-nav-link">Как работает</a>
            <a href="#Pochemu" class="navbar-link w-nav-link">Почему мы</a>
            <a href="#Faq" class="navbar-link w-nav-link">Вопросы-ответы</a>
          </div>
          <div class="w-layout-vflex paliteco">
            <a @click="goToDoc('privacy-policy')" class="link-heder">Политика конфиденциальности</a>
<!--            <a href="#" class="link-heder">Пользовательское соглашение</a>-->
<!--            <a href="#" class="link-heder">Политика обработки персональных данных</a>-->
          </div>
        </nav>
        <div id="w-node-_11c4cfc9-926a-e542-1b82-38094d6cd2eb-4d6cd2e0" class="navbar-button-wrapper">
          <div class="navbar-menu-button w-nav-button">
            <div class="w-layout-hflex mine-menu">
              <div class="menu-icon2">
                <div class="menu-icon2-line-top"></div>
                <div class="menu-icon2-line-middle">
                  <div class="menu-icon-line-middle-inner"></div>
                </div>
                <div class="menu-icon2-line-bottom"></div>
              </div>
              <div class="button-text-menu">Меню</div>
            </div>
          </div>
          <a @click="goToLogin" class="button-flex w-inline-block"><img src="../../../../../public/images/User.svg" loading="lazy" alt="" class="icon">
            <div class="button-primary">Личный кабинет</div>
          </a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'HeaderPersonal',
  computed: {
    currentRoute() {
      return this.$route.path;
    }
  },
  methods: {
    goHome() {
      this.$router.push('/');
    },
    goToDoc(documentType) {
      this.$router.push('/documents/' + documentType);
    },
    goToLogin() {
      if (this.$store.getters.AUTH_TOKEN) {
        this.$router.push('/personal-data');
      } else {
        this.$router.push('/login');
      }
    }
  }
};
</script>

<style>
</style>
