import mainRoutes from "@/app/client/main/main-routes";
import loginRoutes from '@/app/client/login/login-routes';
import registrationRoutes from '@/app/client/registration/registration-routes';
import personalDataRoutes from '@/app/client/personal_data/personal-data-routes';
import authorizationRoutes from '@/app/client/authorization/authorization-routes';
import creditRatingRoutes from '@/app/client/credit_rating/credit-rating-routes';
import documentsRoutes from '@/app/client/documents/documents-routes';

const clientRoutes = [
  ...mainRoutes,
  ...loginRoutes,
  ...registrationRoutes,
  ...personalDataRoutes,
  ...authorizationRoutes,
  ...creditRatingRoutes,
  ...documentsRoutes
];

export default clientRoutes;
