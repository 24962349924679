import Vue from 'vue';
import Router from 'vue-router';
import { PageNotFound } from './shared/components';
import store from "./app-state";
import clientRoutes from "@/app/client/client-routes";

const baseRoutes = [
  {
    path: '/',
    redirect: '/main',
  },
  {
    path: '/404',
    name: 'Page404',
    component: PageNotFound,
    meta: {
      title: 'Страница не найдена',
      wfPage: '6667fca8692ff013d2a6a92b',
      wfSite: '652921c910bae02d8870dddf'
    },
  },
];

Vue.use(Router);
const router = new Router({
  mode: 'hash',
  routes: [
    ...baseRoutes,
    ...clientRoutes,
    {
      path: '*',
      redirect: '/404',
    },
  ],
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Всем займы';
  if (to.meta.wfPage) {
    document.documentElement.setAttribute('data-wf-page', to.meta.wfPage);
  }
  next();
});

export default router;
