const toFormData = (obj) => {
  const formData = new FormData();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      let data = obj[key];
      if (obj[key] instanceof Object)
        data = JSON.stringify(obj[key])
      if (obj[key] instanceof File)
        data = obj[key];
      formData.append(key, data);
    }
  }
  return formData;
};

export {toFormData};
