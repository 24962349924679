import { Middlewares } from "@/app/shared/services/app-router/middlewares";
import Guard from "vue-router-multiguard";

export default [
  {
    path: '/active-loans',
    name: 'ActiveLoans',
    component: () => import('./views/activeLoans.vue'),
    meta: {
      title: 'Актуальные займы',
      wfPage: '666a6ef684eb398c331e9398'
    },
    beforeEnter: Guard([Middlewares.authRequired])
  },
  {
    path: '/calculator',
    name: 'Calculator',
    component: () => import('./views/calculator.vue'),
    meta: {
      title: 'Калькулятор',
      wfPage: '66682f534ebd3c1a5e4243fb'
    },
    beforeEnter: Guard([Middlewares.authRequired])
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('./views/faq.vue'),
    meta: {
      title: 'Вопросы-ответы',
      wfPage: '66681f461fa65c6cef9d4876'
    },
    beforeEnter: Guard([Middlewares.authRequired])
  },
  {
    path: '/map',
    name: 'Map',
    component: () => import('./views/map.vue'),
    meta: {
      title: 'Карта',
      wfPage: '666a761784eb398c3322ec8b'
    },
    beforeEnter: Guard([Middlewares.authRequired])
  },
  {
    path: '/personal-data',
    name: 'PersonalData',
    component: () => import('./views/personalData.vue'),
    meta: {
      title: 'Личные данные',
      wfPage: '6667fc8089ed7ce9f6a63d6e'
    },
    beforeEnter: Guard([Middlewares.authRequired])
  }
];
