import { AxiosClient } from '@/app/shared/services';
import { toFormData } from "@/app/shared/services/helpers";

const state = {
  questions: [
    {
      id: 1,
      text: "Вы уже брали кредит?",
      answer: null,
      answers: [
        { id: 1, text: "Есть действующие, еще не погашены", value: 1 },
        { id: 2, text: "Было погашено до 5 кредитов", value: 2 },
        { id: 3, text: "Было погашено до 10 кредитов", value: 3 },
        { id: 4, text: "Было погашено более 10 кредитов", value: 4 },
      ]
    },
    {
      id: 2,
      text: "Какой у вас тип занятости?",
      answer: null,
      answers: [
        { id: 1, text: "Не трудоустроен", value: 0 },
        { id: 2, text: "Пенсионер", value: 1 },
        { id: 3, text: "Работник (по трудовому договору)", value: 3 },
        { id: 4, text: "Военнослужащий / Госслужащий", value: 4 },
        { id: 5, text: "Индивидуальный предприниматель", value: 5 },
        { id: 6, text: "Руководитель", value: 6 },
      ]
    },
    {
      id: 3,
      text: "Укажите ваш стаж на текущем месте работы",
      answer: null,
      answers: [
        { id: 1, text: "Менее 3 месяцев", value: 0 },
        { id: 2, text: "От 3 до 6 месяцев", value: 2 },
        { id: 3, text: "Менее года", value: 3 },
        { id: 4, text: "От 1 до 3 лет", value: 4 },
        { id: 5, text: "Более 3 лет", value: 5 },
      ]
    },
    {
      id: 4,
      text: "Укажите ваш возраст",
      answer: null,
      answers: [
        { id: 1, text: "18-21 год", value: 5 },
        { id: 2, text: "22-25 лет", value: 7 },
        { id: 3, text: "26-29 лет", value: 6 },
        { id: 4, text: "30-35 лет", value: 4 },
        { id: 5, text: "36-55 лет", value: 3 },
        { id: 6, text: "56-60 лет", value: 2 },
        { id: 7, text: "более 60 лет", value: 1 },
      ]
    },
    {
      id: 5,
      text: "Укажите ваш примерный доход в месяц",
      answer: null,
      answers: [
        { id: 1, text: "Менее 20 000 руб.", value: 0 },
        { id: 2, text: "30 000 руб.", value: 1 },
        { id: 3, text: "40 000 руб.", value: 2 },
        { id: 4, text: "50 000 руб.", value: 3 },
        { id: 5, text: "70 000 руб.", value: 5 },
        { id: 6, text: "Более 100 000 руб.", value: 7 },
      ]
    }
  ],
  RATE_INDEX: 35
};

const getters = {
  QUESTIONS: (state) => state.questions,
  RATE_INDEX: (state) => state.RATE_INDEX
};

const mutations = {
};

const actions = {
  UPDATE_CREDIT_RATING: (context, credit_rating) => {
    return new Promise((resolve, reject) => {
      console.info('Отправляем рейтинг');

      let payload = {
        credit_rating: credit_rating
      };

      AxiosClient.post(`/profiles/credit-rating`, payload)
        .then(() => {
          console.log('Рейтинг обновлён');
          resolve();
        })
        .catch((error) => {
          console.error('Ошибка обновления кредитного рейтинга: ', error);
          reject();
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
