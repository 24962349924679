import { Middlewares } from "@/app/shared/services/app-router/middlewares";
import Guard from "vue-router-multiguard";

export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import('./views/login.vue'),
    meta: {
      title: 'Вход',
      wfPage: '66628b11ce9ac8f5a081c327'
    }
  },
  {
    path: '/login-check-phone',
    name: 'LoginCheckPhone',
    component: () => import('./views/login_check_phone.vue'),
    meta: {
      title: 'Подтверждение номера',
      wfPage: '66628b11ce9ac8f5a081c327'
    }
  }
];
