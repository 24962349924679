import Axios from 'axios';
import store from "@/app/app-state";

Axios.defaults.baseURL = process.env.VUE_APP_BACKEND_PATH;

let authToken = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";
console.log('authToken (middleware): ', authToken);

Axios.defaults.headers.common.Authorization = Axios.defaults.headers.common.Authorization
  ? Axios.defaults.headers.common.Authorization
  : authToken;

// Сохраняем заголовки запроса в локальной переменной
const AxiosClient = Axios.create();

AxiosClient.interceptors.response.use(
  response => response,
  async error => {
    if (error.response && error.response.data && error.response.data.exception === "AccessTokenAlreadyExpired") {
      try {
        // отправляем запрос на обновление токена
        console.log('REFRESHING TOKEN...');
        const refreshToken = localStorage.getItem("refresh_token");
        if (!refreshToken) {
          throw new Error("No refresh token available");
        }

        Axios.defaults.headers.common.Authorization = `Bearer ${refreshToken}`;

        const response = await Axios.post(
          `${process.env.VUE_APP_BACKEND_PATH}/profiles/refresh`,
          { token: refreshToken });
        const newAuthToken = response.data.access_token;
        store.commit('SET_AUTH_TOKEN', newAuthToken);

        // выполняем запрос с ошибкой заново
        console.log('Повторяем запрос с новым токеном');
        error.config.headers.Authorization = `Bearer ${newAuthToken}`;
        return AxiosClient(error.config);
      } catch (refreshError) {
        console.error("Error refreshing token:", refreshError);
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default AxiosClient;
