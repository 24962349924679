import { Middlewares } from "@/app/shared/services/app-router/middlewares";
import Guard from "vue-router-multiguard";

export default [
  {
    path: '/registration-step1',
    name: 'RegistrationStep1',
    component: () => import('./views/page1.vue'),
    meta: {
      title: 'Регистрация',
      wfPage: '652921c910bae02d8870dddd'
    }
  },
  {
    path: '/registration-step1-phone',
    name: 'RegistrationStep1Phone',
    component: () => import('./views/page1-phone.vue'),
    meta: {
      title: 'Подтверждение телефона',
      wfPage: '66668e6af010f14082efc493'
    }
  },
  {
    path: "/registration-step2",
    name: "RegistrationStep2",
    component: () => import("./views/page2.vue"),
    meta: {
      title: 'Регистрация',
      wfPage: '6666a4302d30112b2295738b'
    }
  },
  {
    path: "/registration-step3",
    name: "RegistrationStep3",
    component: () => import("./views/page3.vue"),
    meta: {
      title: 'Регистрация',
      wfPage: '6666afa0475bacb265fce522'
    }
  },
  {
    path: '/registration-step4',
    name: 'RegistrationStep4',
    component: () => import('./views/page4.vue'),
    meta: {
      title: 'Регистрация',
      wfPage: '6666f36e81c9970423fa5480'
    }
  }
  ];
