import { AxiosClient } from '@/app/shared/services';
import { toFormData } from "@/app/shared/services/helpers";

const state = {
};

const getters = {
};

const mutations = {
};

const actions = {
};

export default {
  state,
  getters,
  mutations,
  actions,
};
