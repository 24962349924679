import VuePageTransition from 'vue-page-transition';
import Vue from 'vue';
import App from './app/app.vue';
import store from './app/app-state';
import router from './app/app-routes';
import Buefy from 'buefy';
import Toaster from './plugins/toaster';
import filters from "./app/shared/filters";
import vuetify from './plugins/vuetify';
import VueTheMask from 'vue-the-mask'


import './app/styles_tables.css';

Vue.use(VuePageTransition);
Vue.use(Buefy);
Vue.use(Toaster);
Vue.use(filters);
Vue.use(VueTheMask);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
