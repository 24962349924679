import { Middlewares } from "@/app/shared/services/app-router/middlewares";
import Guard from "vue-router-multiguard";

export default [
  {
    path: '/main',
    name: 'Main',
    component: () => import('./views/main.vue'),
    meta: {
      title: 'Главная страница',
      wfPage: '652921c910bae02d8870dddc'
    }
  }
];
